<template>
  <div class="page-settings">
    <h2>Settings</h2>
    <el-tabs type="border-card" v-model="selectedTab">
      <el-tab-pane label="Profile" name="profile" key="profile">
        <el-form class="box-card" @submit.native.prevent ref="form-profile">
          <el-form-item label="Email">
            <el-input disabled v-model="email"></el-input>
          </el-form-item>

          <el-form-item class="mb4" label="Name">
            <el-input class="display-name" v-model="displayName"></el-input>
          </el-form-item>
          <el-button
            native-type="submit"
            class="mb3"
            :icon="$wait.is('updatingProfile') ? 'el-icon-loading' : ''"
            @click="updateAccount"
            type="primary"
            >SAVE</el-button
          >
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="Account" name="account" key="account">
        <el-form
          v-if="connectedSocialAccount === 'WORDPRESS'"
          class="box-card"
          @submit.native.prevent
          :rules="rulesUpdatePass"
          :model="formFieldsUpdatePass"
          ref="form-account"
        >
          <el-form-item prop="currPassword" required>
            <el-input
              type="password"
              placeholder="Current Password"
              v-model="formFieldsUpdatePass.currPassword"
            ></el-input>
          </el-form-item>
          <el-form-item prop="newPassword" required>
            <el-input
              type="password"
              placeholder="New Password"
              v-model="formFieldsUpdatePass.newPassword"
            ></el-input>
          </el-form-item>

          <el-button
            native-type="submit"
            :icon="$wait.is('updatingPassword') ? 'el-icon-loading' : ''"
            class="mb3"
            @click="changePassword"
            type="primary"
            >CHANGE PASSWORD</el-button
          >
        </el-form>

        <el-divider v-if="connectedSocialAccount === 'WORDPRESS'" />

        <el-button
          class="mt3 mb3"
          @click="deleteAccount"
          type="danger"
          :icon="$wait.is('deletingAccount') ? 'el-icon-loading' : ''"
          >DELETE ACCOUNT</el-button
        >
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { deleteS3Folder, loadAWSsdk } from "@/libs/aws-manager";

export default {
  data() {
    return {
      selectedTab: "profile",
      displayName: "",
      email: "",
      formFieldsUpdatePass: {
        currPassword: "",
        newPassword: "",
      },
      rulesUpdatePass: {
        currPassword: [
          {
            required: true,
            message: "Please enter your current password",
            trigger: "blur",
          },
        ],
        newPassword: [
          {
            min: 6,
            message: "New password should be at least 6 characters",
            trigger: "blur",
          },
        ],
      },
      showChangePasswordBox: false,
    };
  },
  computed: {
    ...mapActions("authModule", [
      "updatePassword",
      "deleteMyAccount",
      "updateUser",
      "getUserInfo",
    ]),
    ...mapGetters("authModule", ["userInfo"]),
    connectedSocialAccount: function() {
      return this.userInfo ? this.userInfo.connected_social_account_type : null;
    },
  },
  mounted: function() {
    loadAWSsdk();
    this.displayName = this.userInfo.display_name;
    this.email = this.userInfo.email;
  },
  methods: {
    handleTabChange(tab, e) {},
    deleteAccount() {
      this.$confirm(
        "CAUTION! Are you sure you want to delete your account? All your projects and files will be deleted!",
        "Error",
        {
          confirmButtonText: "Yes! Delete Everything",
          cancelButtonText: "No",
          confirmButtonClass: "bg-danger",
          cancelButtonClass: "bg-base",
          type: "error",
        }
      )
        .then(() => {
          this.$wait.start("deletingAccount");
          const userId = this.userInfo._id;
          const email = this.userInfo.email;

          this.$store
            .dispatch("authModule/deleteMyAccount", {
              id: userId,
              email: email,
            })
            .then(() => {
              if (userId) {
                deleteS3Folder(userId)
                  .then(() => {
                    this.$wait.end("deletingAccount");

                    this.$router.push("/login");
                  })
                  .catch((e) => {
                    this.$message({
                      type: "error",
                      message:
                        "There was some problem while deleting all your files. Please contact the administrators",
                    });
                    this.$wait.end("deletingAccount");
                  });
              } else {
                this.$wait.end("deletingAccount");
              }
            })
            .catch((e) => {
              this.$message({
                type: "error",
                message:
                  "There was some problem while deleting your account. Please contact the administrators",
              });
              this.$wait.end("deletingAccount");
            });
        })
        .catch((e) => {
          this.$wait.end("deletingAccount");
        });
    },
    updateAccount() {
      this.$wait.start("updatingProfile");

      const dataToUpdate = {
        display_name: this.displayName,
      };
      this.$store
        .dispatch("authModule/updateMyAccount", {
          id: this.userInfo._id,
          data: dataToUpdate,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              type: "success",
              message: "Profile updated successfully",
            });
            this.$store.dispatch("authModule/getUserInfo", this.userInfo._id);
          }
          this.$wait.end("updatingProfile");
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: "There was some problem while updating your profile.",
          });
          this.$wait.end("updatingProfile");
        });
    },
    changePassword() {
      this.$wait.start("updatingPassword");

      const payload = {
        email: this.userInfo.email,
        password: this.formFieldsUpdatePass.currPassword,
        newPassword: this.formFieldsUpdatePass.newPassword,
      };
      this.$store
        .dispatch("authModule/updatePassword", payload)
        .then((res) => {
          if (res.data.status === 200) {
            this.$message({
              type: "success",
              message: "Password updated successfully",
            });
            this.$refs["form-account"].resetFields();
          }
          this.$wait.end("updatingPassword");
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message:
              e.data.indexOf("password you entered for") > -1
                ? "Current Password is incorrect"
                : "Unknown Error",
          });
          this.$wait.end("updatingPassword");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "settings.component.scss";
</style>
